import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SEO from "../../components/seo"
import ImageCard from "../../components/image-card"
import Breadcrumbs from "../../components/breadcrumbs"
import {
  CovidSafetyLink,
  HomeLink,
  TechnologyLink,
} from "../../components/links"
import Alert from "../../components/alert"

const CovidSafetyPage = ({ data }) => {
  const images = {
    aerosolSuction: getImage(data.aerosolSuction),
    airdog: getImage(data.airdog),
    enclosedOperatories: getImage(data.enclosedOperatories),
    gogglesSterilization: getImage(data.gogglesSterilization),
    iqair: getImage(data.iqair),
    viruskiller: getImage(data.viruskiller),
    waitingArea: getImage(data.waitingArea),
  }
  return (
    <>
      <SEO title="COVID-19 safety" />

      <Breadcrumbs>
        <HomeLink />
        <TechnologyLink />
        <CovidSafetyLink />
      </Breadcrumbs>

      <article className="prose dark:prose-invert max-w-none prose-headings:text-brand-primary prose-headings:dark:text-brand-secondary">
        <h2>COVID-19 safety</h2>
        <Alert>
          <span className="text-brand-primary dark:text-brand-secondary font-bold">
            <i>We take the health and safety of our patients very seriously.</i>
          </span>
        </Alert>
        <div className="grid grid-cols-1">
          <div>
            <h3>The following will apply to your appointments.</h3>
            <ul>
              <li>Temperature record and screening for all patients</li>
              <li>
                Social distancing and a limited number of patients in the
                operatory
              </li>
              <li>Strict adherence to infection control protocols</li>
              <li>Adherence to personal protective equipment</li>
              <li>Fully enclosed glass operatories</li>
              <li>UV sterilization and full sanitization for goggles</li>
              <li>Enhanced clinic air filtration and sanitization systems</li>
            </ul>
          </div>
        </div>
        <div className="divider"></div>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <ImageCard
            image={
              <GatsbyImage
                image={images.waitingArea}
                alt="Spacious waiting area"
                className="rounded-2xl"
              />
            }
            name="Spacious Waiting Area"
          ></ImageCard>
          <ImageCard
            image={
              <GatsbyImage
                image={images.enclosedOperatories}
                alt="Enclosed glass operatories"
                className="rounded-2xl"
              />
            }
            name="Enclosed Glass Operatories"
          ></ImageCard>
          <ImageCard
            image={
              <GatsbyImage
                image={images.aerosolSuction}
                alt="Aerosol evacuation system"
                className="rounded-2xl"
              />
            }
            name="Aerosol Evacuation System"
          ></ImageCard>
          <ImageCard
            image={
              <GatsbyImage
                image={images.iqair}
                alt="IQAir air filtration system (Swiss)"
                className="rounded-2xl"
              />
            }
            name="IQAir Air Filtration (Swiss)"
          ></ImageCard>
          <ImageCard
            image={
              <GatsbyImage
                image={images.viruskiller}
                alt="Viruskiller air filtration system (Korean)"
                className="rounded-2xl"
              />
            }
            name="Viruskiller Air Filtration (Korean)"
          ></ImageCard>

          <ImageCard
            image={
              <GatsbyImage
                image={images.airdog}
                alt="AirDog air filtration system (US)"
                className="rounded-2xl"
              />
            }
            name="AirDog Air Filtration (American)"
          ></ImageCard>
          <ImageCard
            image={
              <GatsbyImage
                image={images.gogglesSterilization}
                alt="UV goggles sterilization system"
                className="rounded-2xl"
              />
            }
            name="UV Goggles Sterilization"
          ></ImageCard>
        </div>
      </article>
    </>
  )
}
export default CovidSafetyPage

export const query = graphql`
  query {
    aerosolSuction: file(relativePath: { eq: "safety/aerosol-suction.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 480
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    airdog: file(relativePath: { eq: "safety/airdog.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 480
          height: 480
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          transformOptions: { cropFocus: CENTER }
        )
      }
    }
    enclosedOperatories: file(
      relativePath: { eq: "safety/enclosed-operatories.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 480
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    gogglesSterilization: file(
      relativePath: { eq: "safety/goggles-sterilization.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 480
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    iqair: file(relativePath: { eq: "safety/iqair.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 480
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    viruskiller: file(relativePath: { eq: "safety/viruskiller.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 480
          height: 480
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    waitingArea: file(relativePath: { eq: "safety/waiting-area.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 480
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

import React from "react"
import PropTypes from "prop-types"
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  LightBulbIcon,
} from "@heroicons/react/outline"

const Alert = ({ children, type }) => {
  let icon
  let alertClass = ""
  switch (type) {
    case "success":
      alertClass = "alert-success"
      icon = (
        <CheckCircleIcon className="w-6 min-w-[20px] mr-2 self-center stroke-current" />
      )
      break
    case "error":
      alertClass = "alert-error"
      icon = (
        <ExclamationCircleIcon className="w-6 min-w-[20px] mr-2 self-center stroke-current" />
      )
      break
    case "lightbulb":
      icon = (
        <LightBulbIcon
          className="w-6 min-w-[20px] mr-2 self-center"
          stroke="#d4af37"
        />
      )
      break
    default:
      icon = (
        <InformationCircleIcon
          className="w-6 min-w-[20px] mr-2 self-center"
          stroke="#2196f3"
        />
      )
  }
  return (
    <>
      <div className={"alert text-xl" + (alertClass ? " " + alertClass : "")}>
        <div className="flex-1">
          {icon}
          <span>{children}</span>
        </div>
      </div>
    </>
  )
}

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
}

Alert.defaultProps = {
  type: "info",
}

export default Alert

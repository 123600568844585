import React from "react"
import PropTypes from "prop-types"

const ImageCard = ({ children, image, name }) => (
  <>
    <div className="card">
      <figure className="mt-6 mb-0 px-4">{image}</figure>
      <div className="p-2 card-body">
        <h2 className="m-2 card-title">{name}</h2>
        {children}
      </div>
    </div>
  </>
)

ImageCard.propTypes = {
  image: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
}

export default ImageCard

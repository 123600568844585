import React, { Children } from "react"
import PropTypes from "prop-types"

const Breadcrumbs = ({ children }) => {
  const arrayChildren = Children.toArray(children)

  return (
    <div className="text-md breadcrumbs">
      <ul>
        {Children.map(arrayChildren, (child, index) => {
          return <li key={index}>{child}</li>
        })}
      </ul>
    </div>
  )
}

Breadcrumbs.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Breadcrumbs
